.App {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-flow: column;
  height: 100%;
}

img {
  max-width: 100%;
}

.description {
  text-align: center;
  max-width: 700px;
  margin: 40px auto;
  line-height: 2em;
  font-size: 21px;
}

.wishlist,
.wishlist-with-icon {
  display: block;
  width: 214px;
  height: 43px;
  font-family: inherit;
  color: white;
  font-weight: 700;
  font-size: 16px;
  background-color: #e41e68;
  border-radius: 2px;
  border: none;
  margin: 0 auto;
  cursor: pointer;
  letter-spacing: 3px;
  text-decoration: none;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.wishlist:hover,
.wishlist-with-icon:hover {
  background-color: #2c37ca;
}

.wishlist-with-icon > span {
  margin-right: 10px;
}

.App-footer {
  padding: 10px;
  text-align: center;
  color: #516793;
  flex: 0 1 auto;
  background-color: #171e2c;
}

.App-footer a {
  color: #e41e68;
  cursor: pointer;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}

.icon svg {
  fill: white;
  width: 43px;
  height: 43px;
}

.icon svg:hover {
  fill: #2c37ca;
}

@media (max-width: 600px) {
  img {
    max-width: 100% !important;
  }
}
