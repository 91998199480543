.screenshots {
  margin: auto;
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.screenshots-grid {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.screenshots-left,
.screenshots-right {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.screenshots-left > img,
.screenshots-right > img {
  cursor: pointer;
}

.screenshots-grid img {
  display: block;
  max-width: 480px;
  margin: 10px;
  width: auto;
  height: auto;
}

.car-img {
  margin: 50px 10px -100px 10px;
}

@media (max-width: 1000px) {
  .screenshots-grid {
    flex-direction: column;
  }
}
