html,
body,
#root {
  height: 100%;
  background-color: #0a112c;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.line {
  margin: auto;
  width: 82px;
  height: 1px;
  object-fit: contain;
  background-color: #f4dfa0;
}

.pink-btn {
  display: block;
  font-family: inherit;
  color: white;
  font-weight: 700;
  font-size: 16px;
  background-color: #e41e68;
  border-radius: 2px;
  border: none;
  padding: 15px 25px;
  margin: 10px;
  cursor: pointer;
  letter-spacing: 3px;
  text-transform: uppercase;
}
