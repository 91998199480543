.company {
  width: 100%;
  color: white;
  width: 100%;
  text-align: center;
  background: linear-gradient(to top, #0e2247, #026299);
  clip-path: polygon(0 15%, 100% 0, 100% 90%, 0 100%);
  z-index: -1;
  padding-top: 70px;
  padding-bottom: 150px;
  margin-bottom: -100px;
}

.company-content {
  width: fit-content;
  margin: 50px auto;
  padding: 0 10px;
}

.company-content > h1 {
  text-transform: uppercase;
  letter-spacing: 3.8px;
  font-size: 38px;
}

.company-desc {
  max-width: 550px;
  margin: 30px auto;
  line-height: 150%;
  font-size: 18px;
}

.company-ctas {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.company-ctas > a {
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.company-ctas > a:hover {
  background-color: #2c37ca;
}

@media (max-width: 410px) {
  .company-ctas {
    flex-direction: column;
  }
}
