.features {
  object-fit: contain;
  background-image: linear-gradient(to top, #0e2247, #026299);
  text-align: center;
  color: white;
  padding: 72px 0 111px 0;
}

.features > h1 {
  font-size: 38px;
  letter-spacing: 3.8px;
}

.feature {
  max-width: 350px;
  margin: 0 10px;
}

.feature-title {
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 1.44px;
  font-weight: bold;
}

.features-container {
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.feature-description {
  line-height: 150%;
  font-size: 18px;
}

@media (max-width: 800px) {
  .features-container {
    flex-direction: column;
  }
}
