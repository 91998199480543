.header {
  width: 100%;
  background: transparent;
  margin-bottom: -200px;
  z-index: 10;
  letter-spacing: 2.8px;
  min-height: 116px;
}

.wide-header-content > * {
  margin: 0px auto;
  min-width: 200px;
  text-align: center;
}

.wide-header-content {
  max-width: 1200px;
  padding: 28px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header p {
  text-decoration: none;
}

.wide-header a,
.wide-header p,
.dropdown-content > a,
.dropdown-content > p {
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  color: #a2b0bc;
  text-transform: uppercase;
}

.wide-header a:hover,
.wide-header p:hover,
.dropdown-content > a:hover,
.dropdown-content > p:hover {
  color: white;
}

.following {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  padding: 15px 0;
  background-color: #070c16c0;
}

.wishlist-with-icon {
  height: 35px;
  color: white !important;
  padding: 0 20px;
}

.dropdown-header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 60px;
  width: 100%;
}

.dropdown-content {
  position: absolute;
  top: 0;
  right: 0;
  padding: 35px 0;
  background-color: #070c16c0;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropdown-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 20;
}

.dropdown-content > * {
  width: fit-content;
  margin: 0 auto;
  min-width: 200px;
  text-align: center;
}

.dropdown-bg {
  background-color: #070c16c0;
}

.logo-small {
  height: 40px;
  margin-left: 10px;
}

@media (max-width: 800px) {
  .wide-header {
    display: none;
  }

  .header {
    margin-bottom: 0;
    min-height: 0;
  }
}

@media (min-width: 800px) {
  .dropdown-header {
    display: none;
  }
}
