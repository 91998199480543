.hero-section {
  background-image: url("../../images/herobg.png");
  background-repeat: no-repeat;
  background-size: 2151px;
  background-position-x: center;
}

.hero-content {
  margin: 200px auto 0 auto;
  height: 500px;
  background-image: url("../../images/heroimg.png");
  background-repeat: no-repeat;
  background-position-x: right;
  padding: 100px 5px;
  max-width: 1300px;
}

.hero-content-left {
  width: fit-content;
}

.hero-content-left > p {
  max-width: 400px;
  text-shadow: 1px 1.7px 5px rgba(0, 0, 0, 0.66);
  font-family: "Montserrat";
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 6.3px;
  text-align: center;
  color: #ffffff;
  margin: auto;
  margin-top: 26px;
  margin-bottom: 60px;
}

.hero-img {
  display: block;
  margin: auto;
  width: fit-content;
}

.hero-content > * > .wishlist {
  padding: 0 20px;
  width: 250px;
}

.hero-video {
  color: white;
  background-image: url("../../images/badges_bg.png");
  background-repeat: no-repeat;
  background-size: auto;
  padding-bottom: 200px;
  background-position-y: bottom;
  background-position-x: center;
  padding-left: 10px;
  padding-right: 10px;
}

.hero-video > * > .wishlist {
  padding: 0 20px;
  width: 250px;
}

.hero-video-cta {
  display: flex;
  flex-direction: row;
  width: 650px;
  margin: auto;
}

@media (max-width: 800px) {
  .hero-content {
    margin: 50px auto 0 auto;
    background-position-x: center;
    background-size: 100%;
  }
  .hero-content-left {
    width: 100%;
  }

  .hero-img {
    margin: auto;
    margin-top: 100px;
  }
  
  .hero-video-cta {
    flex-direction: column;
    width: auto;
    margin: auto;

    .wishlist:first-of-type {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 400px) {
}
