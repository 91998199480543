.footer {
  background-color: #000000;
  color: #7f8aa7;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  padding: 10px 10px 0 10px;
}

.footer-left,
.footer-right {
  width: 100%;
  text-align: center;
  color: #7f8aa7;
  text-transform: uppercase;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: normal;
  line-height: 1.57;
  letter-spacing: 2.8px;
  margin-top: 20px;
}

.footer-right > p {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 350px) {
  .footer {
    flex-direction: column;
  }
}
